/**
  @Description:
  import { elementExists } from '@/assets/js/mixins/base/ElementExists'

  mixins: [ elementExists ]

  Checks if an element is already added in DOM
**/

export var elementExists = {
  methods: {
    elementExists (el) {
      let result

      if (el) {
        result = true
      } else {
        result = false
        window.requestAnimationFrame(this.elementExists())
      }

      return result
    }
  }
}
