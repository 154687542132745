<template>
  <div
    class="table-body"
    ref="tableBody"
    :style="tableBodyConfig"
  >
    <div
      class="table"
      ref="table"
    >
      <div class="tbody">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
//= mixins
import { detectDevice } from '@/assets/js/mixins/base/DetectDevice'
import { elementExists } from '@/assets/js/mixins/base/ElementExists'

export default {
  name: 'tbl-body',

  inject: {
    tblConfig: {}
  },

  mixins: [
    detectDevice,
    elementExists
  ],

  computed: {
    tableBodyConfig () {
      let result
      let width = this.tblConfig.body_w
      let tableWidth = this.tblConfig.table_w

      if (tableWidth > width) {
        result = tableWidth + 'px'
      } else {
        result = width + 'px'
      }

      return {
        width: result
      }
    }
  },

  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.getTableBodyWidth)
      this.getTableBodyWidth()
    })
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.getTableBodyWidth)
  },

  methods: {
    getTableBodyWidth () {
      let table = this.$el.firstChild
      let tableWrap = this.$el.parentNode.parentNode
      let result = this.elementExists(tableWrap)

      if (result) {
        setTimeout(() => {
          this.tblConfig.body_w = table.clientWidth
          this.tblConfig.bodyTable_w = this.$refs.table.clientWidth
        }, 0)
      }
    },
  }
}
</script>
